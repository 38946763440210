const processInclude = require('app_storefront_base/util');
const CONST = require('plugin_frontend_core/constants');
const {
    slickPreventBeyondEdge
} = require('plugin_frontend_core/utils/utils_cw');

const selectors = {
    tileWrapper: '.js-product',
    tileContainer: '.js-product-tile',
    tileImageContainer: '.js-product-tile-image-container',
    tileImage: '.js-product-tile-image',
    tileSrcSet: '.js-product-tile-srcset',
    tileLink: '.js-product .js-product-tile-link',
    quickViewContainer: '.js-product-quickview',
    quickViewSlider: '.js-product-quickview-slider:not(.slick-initialized)',
    quickViewSlide: '.js-product-quickview-slide',
    colorVariant: '.js-quickview-color-variant',
    productTileDataLayerData: 'input[name="productTileDatalayerData"]',
    pdCarouselDatalayerListValue: 'input[name="PDProductsCarouselDatalayerListName"]',
    datalayerDataSelectors: [
        'input[name="plpDatalayerData"]',
        'input[name="recommendedProductsDatalayerData"]'
    ],
    datalayerProductDetailEventListName: 'input[name=datalayerProductDetailEventListName]'
};
/**
 * Returns list value for the given product ID
 * @param {string} pid Product ID
 * @return {null|string} null or list value for the given product ID
 */
function getListValue(pid) {
    const pdCarouselDatalayerListValue = $(selectors.pdCarouselDatalayerListValue);

    if (pdCarouselDatalayerListValue.length) {
        return $(pdCarouselDatalayerListValue[0]).val();
    }

    if ($(selectors.tileWrapper + '[data-pid="' + pid + '"]').data('isrelatedproduct') === 1) {
        return 'RelatedProducts';
    }

    let listValue = null;
    selectors.datalayerDataSelectors.forEach(function (selector) {
        const inputs = $(selector);

        if (!inputs.length) {
            return;
        }

        const dataArr = JSON.parse($(inputs[0]).val());

        const datalayerData = dataArr.find(function (data) {
            return data.id === pid;
        });

        if (datalayerData) {
            listValue = datalayerData.list;
        }
    });
    if (listValue !== null) return listValue;
    if ($(selectors.datalayerProductDetailEventListName).length) {
        return $($(selectors.datalayerProductDetailEventListName)[0]).val();
    }
    return listValue;
}

/**
 * Sets list value for impression products
 * @param {Array<Object>} impressionProducts array of impression product object
 */
function setListValueForImpressionProducts(impressionProducts) {
    impressionProducts.forEach(function (impressionProduct) {
        const pid = impressionProduct.id;

        // eslint-disable-next-line no-param-reassign
        impressionProduct.list = getListValue(pid);
    });
}

/**
 * Sets position value for impression products
 * @param {Array<Object>} impressionProducts array of impression product object
 */
function setPositionValue(impressionProducts) {
    impressionProducts.forEach(function (impressionProduct) {
        const pid = impressionProduct.id;

        const $tileWrappers = $(selectors.tileWrapper + '[data-pid="' + pid + '"]');

        let index = 0;
        if ($tileWrappers.parents('.slick-slide').length) {
            $tileWrappers.each(function () {
                const slickIndex = $(this).parents('.slick-slide').data('slick-index');

                if (slickIndex >= 0) {
                    index = slickIndex;
                    return false;
                }
            });
        } else {
            index = $($tileWrappers[0]).parent().index();
        }

        // eslint-disable-next-line no-param-reassign
        impressionProduct.position = ++index;
    });
}

document.addEventListener('productImpression', function (e) {
    if (e.detail.length > 0) {
        setListValueForImpressionProducts(e.detail);
        setPositionValue(e.detail);
        $(document).trigger('productTileImpression', [e.detail]);
    }
});

const options = {
    rootMargin: '-20% 0px -20% 0px',
    threshold: 0.5
};

/**
 *
 * Create observer for tracking product impression
 */
function createObserver() {
    let observer = new IntersectionObserver((entries) => {
        var productImpressions = [];
        for (const entry of entries) {
            let datalayerObject = $($(entry.target)[0]).find(selectors.productTileDataLayerData).val();
            if (entry.isIntersecting && productImpressions.indexOf(datalayerObject) === -1) {
                productImpressions.push(JSON.parse(datalayerObject));
                observer.unobserve(entry.target);
            }
        }
        // Create the event and pushes array of IDs
        var event = new CustomEvent('productImpression', {
            detail: productImpressions
        });
        // Dispatch the event
        document.dispatchEvent(event);
    }, options);
    return observer;
}


/**
 *
 * Find products in viewport function
 */
function inViewPort() {
    let myElements = document.querySelectorAll(selectors.tileContainer);
    if (myElements.length > 0) {
        let observer = createObserver();
        myElements.forEach(elem => {
            observer.observe(elem);
            $(elem).attr('data-observed', 1);
        });
    }
}

/**
 *
 * Find products in viewport function when Carousel Loads
 */
function onEinsteinCarouselLoad() {
    $('body').on('einstein:loaded:gtm', function () {
        $(document).ready(function () {
            let myElements = document.querySelectorAll(selectors.tileContainer);
            if (myElements.length > 0) {
                let observer = createObserver();
                myElements.forEach(elem => {
                    if (!$(elem).attr('data-observed')) {
                        observer.observe(elem);
                        $(elem).attr('data-observed', 1);
                    }
                });
            }
        });
    });
}

const attributes = {
    dataImage: 'data-image',
    dataRetinaImage: 'data-image', // TODO LROS: change this
    dataHoverImage: 'data-hover-image',
    dataHoverRetinaImage: 'data-hover-image', // TODO LROS: change this
    dataQuickViewUrl: 'data-quickview'
};

const classes = {
    imageReplaced: 'img-replaced'
};

processInclude(require('plugin_catalog/product/quickview'));
processInclude(require('plugin_wishlists/product/tile'));

const $document = $(document);

/**
 * Listen for click event on product tile to save datalayer list of product where it is listed to use with PDP detail event.
 */
function datalayerEvents() {
    /**
     * Checks if the clicked product is a real product tile, since the selector class name is used in other places as well!
     *
     * @param {Object} $product Jquery object that addresses product element
     * @return {boolean} Returns true if the product given DOM element is a real product tile otherwise false
     */
    function isProductTile($product) {
        return ($product.parent().hasClass('js-product-cross-sell-item') ||
            !!$product.closest('.slick-slide').length ||
            !!$product.closest('.js-product-grid').length) && $product.closest('.js-pd-product-carousel').length === 0;
    }

    $(document).ready(function () {
        $(document).on('click', selectors.tileWrapper, function (event) {
            const $product = $(this);
            if (!isProductTile($product)) {
                return;
            }

            const productId = $product.data('pid');
            const listvalue = getListValue(productId);
            $document.trigger('plp:setListName', [productId, listvalue]);
        });
    });
}

/**
 * Returns position number of the tile.
 * @param {Object} $tileWrapperJqueryObject GTM object of the tile
 * @return {number} Integer value
 */
function getTilePosition($tileWrapperJqueryObject) {
    const $tileParent = $tileWrapperJqueryObject.parent();
    let tileIndex = 0;

    if ($tileParent.hasClass('js-product-tile__column')) {
        tileIndex = $tileParent.index();
    } else if ($tileParent.hasClass('js-product-carousel-item')) {
        tileIndex = $tileParent.data('slick-index');
    } else {
        const $tileOuterParent = $tileParent.parent();

        if ($tileOuterParent.hasClass('js-product-carousel-item')) {
            tileIndex = $tileOuterParent.data('slick-index');
        }
    }

    return tileIndex + 1;
}

/**
 * @private onTileLinkClick
 * @param {MouseEvent} event - onClick event
 */
function onTileLinkClick() {
    const $tileWrapper = $(this).closest(selectors.tileWrapper);
    const pid = $tileWrapper.attr(CONST.attributes.product.productID);

    $(CONST.selectors.body).trigger(CONST.events.productTileClick, {
        pid: pid,
        list: getListValue(pid)
    });
}

/**
 * Slicks an element using it's config
 * @param {jQuery} $carousel - Element to slick
 */
function slickElement($carousel) {
    const slickConfig = { // Default config
        infinite: false,
        slide: selectors.quickViewSlide,
        rows: 0,
        variableWidth: true
    };
    // Instance specific config
    const instanceConfig = $carousel.data(CONST.attributes.slick);
    if (instanceConfig) {
        // Merge configs
        const propKeys = Object.keys(instanceConfig);
        for (let i = 0; i < propKeys.length; i++) {
            let propKey = propKeys[i];
            slickConfig[propKey] = instanceConfig[propKey];
        }
    }

    // Check how many slides the carousel has.
    // If the amount of slides is less than the slides to show, "initialSlide" will
    // create an offset while the carousel is not scrollable, resulting in the fact
    // that variations before the selected slide are not in view.
    // Also for some reason, Initial slide should be offset by -1
    const amountOfSlides = $carousel.find(selectors.quickViewSlide).length;
    if (amountOfSlides <= slickConfig.slidesToShow) {
        slickConfig.initialSlide = 0;
    }

    // Create carousel
    $carousel.slick(slickConfig);
    slickPreventBeyondEdge($carousel);
}

/**
 * TODO
 */
function productQuickView() {
    /**
     * TODO
     * @param {MouseEvent} event TODO
     */
    function handleQuickView(event) {
        const $target = $(event.currentTarget);
        const $slickSlider = $target.closest(CONST.selectors.slick.slider);
        const $quickview = $target.find(selectors.quickViewContainer);
        const $quickviewImage = $quickview.find(selectors.tileImage);
        const $quickviewImageContainer = $quickview.find(selectors.tileImageContainer);
        const $quickviewSlider = $quickview.find(selectors.quickViewSlider);

        window.currentQuickview = $target;

        if (event.type === CONST.events.mouseenter) {
            $slickSlider.addClass(CONST.classes.hover);
            $quickviewSlider.each(function () {
                let $slider = $(this);
                slickElement($slider);

                // If there is only 1 size and it;s auto-selected, re-select it to
                // set the data-pid for the quickview
                const $quickviewAttributes = $slider.find(selectors.quickViewSlide);
                if ($quickviewAttributes.length === 1 && $quickviewAttributes.hasClass(CONST.classes.selected)) {
                    $quickviewAttributes.find('a').trigger(CONST.events.click);
                }
            });

            if ($quickviewImageContainer.attr(attributes.dataHoverImage)) {
                if (!$quickviewImage.hasClass(classes.imageReplaced)) {
                    $quickviewImage
                        .attr(CONST.attributes.src, $quickviewImageContainer.attr(attributes.dataHoverImage))
                        .on(CONST.events.load, function onQuickviewImageLoad() {
                            $quickviewImage.addClass(classes.imageReplaced);

                            // The quickview is only shown once the hover image is done loading,
                            // Only show the quickview if it's still the same quickview because
                            // if the user quickly hovers over multiple product tiles, you don't
                            // want to load "previous" quickviews
                            if ($target === window.currentQuickview) {
                                $quickview.addClass(CONST.classes.show);
                            }
                        })
                        ;
                } else {
                    $quickview.addClass(CONST.classes.show);
                }
            } else {
                $quickview.addClass(CONST.classes.show);
            }
        } else {
            $slickSlider.removeClass(CONST.classes.hover);
        }

        if (event.type === CONST.events.mouseleave) {
            $quickview.removeClass(CONST.classes.show);
        }
    }

    $document.on(`${CONST.events.mouseenter} ${CONST.events.mouseleave}`, `${CONST.selectors.notTouch} ${selectors.tileWrapper}`, handleQuickView);
}

function productVariationChange() {
    function getVariationTile(event) {
        event.preventDefault();
        var $elm = $(event.target);
        var $quickViewContainer = $elm.parents(selectors.quickViewContainer);
        var quickViewUrl = $elm.attr(attributes.dataQuickViewUrl);
        if (quickViewUrl) {
            $.ajax({
                url: quickViewUrl,
                method: 'GET',
                success: function (data) {
                    var $quickviewHtml = $(data).find(selectors.quickViewContainer);
                    $quickviewHtml.addClass('show');
                    $quickViewContainer.replaceWith($quickviewHtml);
                    $('body').trigger("calculateWaardechequePrices");
                }
            });
        }
    }

    $document.on(`${CONST.events.click}`, `${selectors.colorVariant}`, getVariationTile);
}

/**
 * TODO
 */
function productHoverImageChange() {
    /**
     * TODO
     * @param {*} tileImage TODO
     * @param {*} tileHoverImage TODO
     * @returns {string} TODO LROS
     */
    function constructSrcSetPath(tileImage, tileHoverImage) {
        return `${tileImage} 1x, ${tileHoverImage}`;
    }

    /**
     * TODO
     * @param {*} event TODO
     */
    function handleHoverImages(event) {
        const $target = $(event.currentTarget);
        const $tile = $target.find(selectors.tileImageContainer);
        let $tileImage = $tile.find(selectors.tileImage);
        let $tileSrcSet = $tile.find(selectors.tileSrcSet);

        let productImages = {
            defaultImage: $tile.attr(attributes.dataImage),
            defaultRetinaImage: $tile.attr(attributes.dataRetinaImage),
            hoverImage: $tile.attr(attributes.dataHoverImage),
            hoverRetinaImage: $tile.attr(attributes.dataHoverRetinaImage)
        };

        if (event.type === CONST.events.mouseenter) {
            $tileImage.attr(CONST.attributes.src, productImages.hoverImage);

            if ($tileSrcSet[0]) {
                $tileSrcSet.attr(
                    CONST.attributes.srcset,
                    constructSrcSetPath(productImages.hoverImage, productImages.hoverRetinaImage)
                );
            }
        }

        if (event.type === CONST.events.mouseleave) {
            $tileImage.attr(CONST.attributes.src, productImages.defaultImage);

            if ($tileSrcSet[0]) {
                $tileSrcSet.attr(
                    CONST.attributes.srcset,
                    constructSrcSetPath(productImages.defaultImage, productImages.defaultRetinaImage)
                );
            }
        }
    }

    // JVP @TODO: Converted current image replacement setup in `handleQuickView` in `handleHoverImages`?
    // $document.on(CONST.events.mouseenter, selectors.tileContainer, handleHoverImages);
    // $document.on(CONST.events.mouseleave, selectors.tileContainer, handleHoverImages);
}

// add trigger for client side action on product tile click
$(CONST.selectors.body).on(CONST.events.click, selectors.tileLink, onTileLinkClick);

$(CONST.selectors.body).on(CONST.events.productAfterAddToCart, function () {
    $('.range__details').removeClass('range__details--waardecheck');
    $('.price').find('.waardecheck').addClass("hidden")
    $('.price').find('.waardecheck__msg').addClass("hidden");
    $('.waardecheck-info').remove();
});

module.exports = {
    productVariationChange: productVariationChange,
    productQuickView: productQuickView,
    productHoverImageChange: productHoverImageChange,
    inViewPort: inViewPort,
    datalayerEvents: datalayerEvents,
    onEinsteinCarouselLoad: onEinsteinCarouselLoad
};
