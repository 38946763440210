const { bonusProductsEnabled } = require('../config.json');
const CONST = require('plugin_frontend_core/constants');
const productBase = require('app_storefront_base/product/base');

const selectors = {
    quickView: '.js-quickview',
    quickViewModal: '#quickViewModal',
    quickViewModalVisible: '.modal.show',
    quickViewModalBody: '.modal-body',
    quickViewModalContent: '.modal-content',
    quickViewModalFooter: '.modal-footer',
    quickViewModalVisibleBundleItems: '.modal.show .product-quickview>.bundle-items',
    quickViewProduct: '.product-quickview',
    productAvailability: '.product-availability',
    globalAvailability: '.global-availability',
    availabilityMessage: '.availability-msg',
    quickViewDialog: '.quick-view-dialog',
    quickViewTrigger: 'a.quickview',
    addToCartGlobal: '.add-to-cart-global',
    addToCartButton: '.js-add-to-cart',
    setItems: '.set-items',
    productId: '.product-id',
    fullPdpLink: '.full-pdp-link',
    sizeChart: '.size-chart'
};

const dataAttributes = {
    readyToOrder: 'ready-to-order',
    available: 'available'
};

const templates = {
    divWrapper: '<div>',
    quickViewModal: `<!-- Modal -->
    <div class="modal fade" id="quickViewModal" role="dialog">
        <div class="modal-dialog quick-view-dialog">
        <!-- Modal content-->
            <div class="modal-content">
                <div class="modal-header">
                    <a class="full-pdp-link" href="">View Full Details</a>
                    <button type="button" class="close pull-right" data-dismiss="modal">
                        &times;
                    </button>
                </div>
            <div class="modal-body"></div>
            <div class="modal-footer"></div>
            </div>
        </div>
    </div>`
};

/**
 * @public isShowing
 * @returns {boolean} isShowing -
 *
 * @description
 * Checks if currently a quickview is open
 */
function isShowing() {
    return $(selectors.quickViewModal).hasClass(CONST.events.show);
}

/**
 * Generates the modal window on the first call.
 * @TODO FH: CWO-185 Move this method
 */
function getModalHtmlElement() {
    const $quickViewModal = $(selectors.quickViewModal);
    if ($quickViewModal.length !== 0) {
        $quickViewModal.remove();
    }

    $(CONST.selectors.body).append(templates.quickViewModal);
}

/**
 * @typedef {Object} QuickViewHtml
 * @property {string} body - Main Quick View body
 * @property {string} footer - Quick View footer content
 */

/**
 * @private parseHtml
 * @param {string} html - Rendered HTML from quickview template
 * @return {QuickViewHtml} - QuickView content components
 *
 * @description
 * Parse HTML code in Ajax response
 */
function parseHtml(html) {
    const $html = $(templates.divWrapper).append($.parseHTML(html));
    const body = $html.find(selectors.quickViewProduct);
    const footer = $html.find(selectors.quickViewModalFooter).children();

    return {
        body: body,
        footer: footer
    };
}

/**
 * @private fillModalElement
 * @param {string} selectedValueUrl - url to be used to retrieve a new product model
 *
 * @description
 * replaces the content in the modal window on for the selected product variation.
 */
function fillModalElement(selectedValueUrl) {
    // @TODO FH CWO-185: Let the modal do it itself
    $(selectors.quickViewModalBody).spinner().start();

    const productService = $.ajax({
        url: selectedValueUrl,
        method: 'GET',
        dataType: 'json'
    });

    productService.then(data => {
        const parsedHtml = parseHtml(data.renderedTemplate);
        const $quickViewModal = $(selectors.quickViewModal);
        const $quickViewModalBody = $(selectors.quickViewModalBody);
        const $quickViewModalFooter = $(selectors.quickViewModalFooter);

        // @TODO FH CWO-185: Modal should handle this part
        $quickViewModalBody.empty();
        $quickViewModalBody.html(parsedHtml.body);
        $quickViewModalFooter.html(parsedHtml.footer);
        $quickViewModal.find(selectors.fullPdpLink).attr(CONST.attributes.href, data.productUrl);
        $quickViewModal.find(selectors.sizeChart).attr(CONST.attributes.href, data.productUrl);
        $quickViewModal.modal(CONST.classes.show);

        $.spinner().stop();
    });

    productService.catch(() => {
        $.spinner().stop();
    });
}

/**
 * @public onQuickviewClick
 * @param {MouseEvent} event - on click event
 */
function onQuickviewClick(event) {
    event.preventDefault();

    const selectedValueUrl = $(event.currentTarget).closest(selectors.quickViewTrigger).attr(CONST.attributes.href);
    $(event.target).trigger(CONST.events.showQuickview);
    getModalHtmlElement();
    fillModalElement(selectedValueUrl);
}

/**
 * @private
 * @param {Event} event custom CONST.events.productAfterAttributeSelect event
 * @param {JSON} response - JSON response
 */
function onAfterProductAttributeSelect(event, response) {
    const $visibleQuickviewModalBundleItems = $(selectors.quickViewModalVisibleBundleItems);
    const $visibleModal = $(selectors.quickViewModalVisible);
    const $setItems = $(selectors.setItems);

    if ($visibleQuickviewModalBundleItems.length) {
        const $responseContainer = $visibleModal.find(response.container);
        $responseContainer.attr(CONST.attributes.product.productID, response.data.product.id);
        $responseContainer.find(selectors.productId).text(response.data.product.id);
    } else if ($setItems.length) {
        response.container.find(selectors.productId).text(response.data.product.id);
    } else {
        $visibleModal.find(selectors.quickViewProduct).attr(CONST.attributes.product.productID, response.data.product.id);
        $visibleModal.find(selectors.fullPdpLink).attr(CONST.attributes.href, response.data.product.selectedProductUrl);
    }
}

/**
 * @private onUpdateAddToCart
 * @param {Event} event - custom CONST.events.product:updateAddToCart event
 * @param {JSON} response - JSON response
 */
function onUpdateAddToCart(event, response) {
    // update global add to cart (single products, bundles)
    const $quickViewDialog = $(response.$productContainer).closest(selectors.quickViewDialog);
    const $dialogGlobalAvailability = $quickViewDialog.find(selectors.globalAvailability);
    const isAvailable = [
        dataAttributes.readyToOrder,
        dataAttributes.available
    ].every(attribute => Boolean($dialogGlobalAvailability.data(attribute)));
    $quickViewDialog.find(selectors.addToCartGlobal).attr(CONST.attributes.disabled, !isAvailable);
}

/**
 * @param {Event} event - custom CONST.events.productAvailabilityUpdated event
 * @param {JSON} response - JSON response
 */
function onUpdateAvailability(event, response) {
    // bundle individual products
    $(selectors.productAvailability, response.$productContainer)
        .data(dataAttributes.readyToOrder, response.product.readyToOrder)
        .data(dataAttributes.available, response.product.available)
        .find(selectors.availabilityMessage)
        .empty()
        .html(response.message);

    const $quickViewDialog = $(response.$productContainer).closest(selectors.quickViewDialog);
    const $dialogGlobalAvailability = $quickViewDialog.find(selectors.globalAvailability);
    const $dialogProductAvailability = $quickViewDialog.find(selectors.productAvailability);

    if ($dialogProductAvailability.length) {
        // bundle all products
        const productAvailabilityArray = $dialogProductAvailability.toArray();
        const areAllAvailable = productAvailabilityArray.every(item => Boolean($(item).data(dataAttributes.available)));
        const areAllOrderable = productAvailabilityArray.every(item => Boolean($(item).data(dataAttributes.readyToOrder)));
        $dialogGlobalAvailability
            .data(dataAttributes.readyToOrder, areAllOrderable)
            .data(dataAttributes.available, areAllAvailable)
            .find(selectors.availabilityMessage)
            .empty()
            .html(areAllOrderable ? response.message : response.resources.info_selectforstock);
    } else {
        // single product
        $dialogGlobalAvailability
            .data(dataAttributes.readyToOrder, response.product.readyToOrder)
            .data(dataAttributes.available, response.product.available)
            .find(selectors.availabilityMessage)
            .empty()
            .html(response.message);
    }
}


const $body = $(CONST.selectors.body);
$body.on(CONST.events.click, selectors.quickView, onQuickviewClick);
$body.on(CONST.events.productAfterAttributeSelect, onAfterProductAttributeSelect);
$body.on(CONST.events.productUpdateAddToCart, onUpdateAddToCart);
$body.on(CONST.events.productAvailabilityUpdated, onUpdateAvailability);

$body.on(CONST.events.beforeAddToCart, (e, data) => {
    $(data).closest(selectors.quickViewModalContent).spinner().start();
});

$body.on(CONST.events.productAfterAddToCart, () => {
    $(selectors.quickViewModal).modal(CONST.events.hide);
});

$body.on(CONST.events.productBeforeAttributeSelect, () => {
    const $quickviewModalContent = $(selectors.quickViewModalVisible).find(selectors.quickViewModalContent);
    if (!$quickviewModalContent.length > 0) {
        return;
    }
    $quickviewModalContent.spinner().start();
});

module.exports = Object.assign({
    selectors: selectors,
    isShowing: isShowing,
    colorAttribute: productBase.colorAttribute,
    selectAttribute: productBase.selectAttribute,
    availability: productBase.availability,
    addToCart: productBase.addToCart
}, bonusProductsEnabled ? {
    removeBonusProduct: productBase.removeBonusProduct,
    selectBonusProduct: productBase.selectBonusProduct,
    enableBonusProductSelection: productBase.enableBonusProductSelection,
    showMoreBonusProducts: productBase.showMoreBonusProducts,
    addBonusProductsToCart: productBase.addBonusProductsToCart
} : {});
